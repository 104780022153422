<template>
  <section class="statistic-controller">
    <div class="statistic-controller__container">
      <StatisticControllerStatisticItem
        v-for="(item, key) in filteredItems"
        :key="key"
        :quantity="item ?? 0"
        :variant="key"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IStatisticControllerProps } from './StatisticController.types';
import type { EStatisticItemVariants } from '~/config/apps/index.types';

import type { TStatistic } from '~/store/statistic/statistic.types';

import { useStatisticStore } from '~/store/statistic/statistic.store';

const {
  statistics: { hideElements },
} = useProjectSettings();

const props = defineProps<IStatisticControllerProps>();
const { keysShow } = toRefs(props);

const statisticStore = useStatisticStore();
const { items } = storeToRefs(statisticStore);

const filteredItems = computed(() => {
  const result: TStatistic = {};
  let key: EStatisticItemVariants;

  for (key in items.value) {
    if (!keysShow.value.includes(key) || hideElements.includes(key)) continue;
    result[key] = items.value[key];
  }

  return result;
});
</script>

<style scoped lang="scss" src="./StatisticController.scss"></style>
